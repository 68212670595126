<template>
    <div class="detail-container">
        <div class="post-header">
        <div class="post-tag">{{research.year}}</div>
        <div class="post-title-container">
            <div v-if="loading" class="post-title">loading...</div>
            <div v-else class="post-title">{{research.name}}</div>
        </div>
        <svg class="Line_41" viewBox="0 0 4000 2">
            <path id="Line_41" d="M 0 0 L 4000 0">
            </path>
        </svg>
        <div class="post-meta">
            <span>연구자:</span> <span>{{participants}}</span>
          </div>
        </div>
        <div class="post-content">
            <p v-if="loading">
                loading...
            </p>
            <div v-else class="content-container">
              <div>
                <p v-html="research.introduction">
                </p>
              </div>
              <div>
                <img :src="research.image_url" alt="Research Image" class="research-image" />
              </div>
            </div>
        </div>
        <div class="post-footer">
        <button @click="goBack" class="back-button">뒤로가기</button>
        </div>
    </div>
</template>

<script>
import axios from 'axios'
export default {
  name: "PostDetail",
  components: {
  },
  data() {
    return {
        research: {}
    }
  },
  computed: {
    participants() {
      console.log(this.research)
      if(!this.research.roles){
        return '';
      }
      const participants = this.research.roles.map(el=>{
        return el.user_name;
      })
      return participants.join(', ');
    }
  },
  methods: {
    goBack() {
      this.$router.go(-1);
    },
    async getData(){
        this.loading = true;
        try{
            const response = await axios.get(`${process.env.VUE_APP_API_URL}/research/${this.$route.params.id}/about`);
            this.research = response.data
            this.research.image_url = `${process.env.VUE_APP_API_URL}/research${this.research.image_url}`
        } catch(error){
            console.error(error);
        }
        this.loading = false;
    },
  },
  created() {
    this.getData();
  }
};
</script>

<style lang="css" scoped src='@/assets/styles/CommonPost.css'>
</style>

<style scoped>

.detail-container .content-container {
  display: flex;
  justify-content: space-between;
}

.detail-container .post-meta {
  font: normal normal medium 16px/24px Noto Sans CJK KR;
  justify-content: left;
  line-height: 24px;
  color: #707070;
  margin: 0;
}
.detail-container .post-meta span:first-child {
  font: normal normal bold 16px/24px Noto Sans CJK KR;
  margin-right: 10px;
}

</style>
