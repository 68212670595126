<template>
  <div class="home">
    <div class="about">
      <home-about></home-about>
    </div>
    <div class="board-container">
      <div class="left-container">
        <home-research></home-research>
      </div>
      <div class="right-container">
        <home-news></home-news>
      </div>
    </div>
    <div class="member-container">
      <home-member></home-member>
    </div>
    <div class="notice-main-container">
      <home-notice></home-notice>
    </div>
  </div>
</template>

<script>
import HomeAbout from "../components/home/HomeAbout.vue"
import HomeNews from "../components/home/HomeNews.vue"
import HomeResearch from "../components/home/HomeResearch.vue"
import HomeMember from "../components/home/HomeMember.vue"
import HomeNotice from "../components/home/HomeNotice.vue"
export default {
  name: 'HomeView',
  components: {
    HomeAbout,
    HomeNews,
    HomeResearch,
    HomeMember,
    HomeNotice
  }
}
</script>
<style scoped>
.section {
  width: 100%;
	border: 2px solid rgb(67, 66, 66);
	position: relative;
	/* border-radius: 10px; */
}
.board-container{
  display: flex;
  width: 100%;
  justify-content: center;
}
.member-container { 
  width: calc(90% + 60px);
  margin: 0 auto;
}
.notice-main-container {
  width: 100%;
  margin-top: 70px;
}

.left-container, .right-container{
  width: 45%;
  padding: 30px;
}





/* small-screen css */

.small-screen .board-container{
  display: block;
}
.small-screen .member-container { 
  width: calc(90%);
}
.small-screen .left-container, .small-screen .right-container{
  width: 90%;
  margin: 0 auto;
  padding: 20px;
}

/* @media (max-width: 1300px) {
  .board-container{
    display: block;
  }
  .member-container { 
    width: calc(90%);
  }
  .left-container, .right-container{
    width: 90%;
    margin: 0 auto;
    padding: 20px;
  }
} */


</style>