<template>
	<div class="permissions-container">
		<div class="permissions-card">
			<h2 class="permissions-title">Permissions Management</h2>
			<div class="user-list">
				<div class="user-item" v-for="(user, index) in users" :key="index">
					<span>{{ user.name }}</span>
					<select v-model="user.role" @focus="storeOriginalRole(user)"  @change="updateUserRole(user, index)">
						<option v-for="role in roles" :key="role" :value="role">{{ role }}</option>
					</select>
					<button @click="removeUser(index)">Delete</button>
				</div>
			</div>
			<button @click="submitChanges" class="submit-button">Submit</button>
		</div>
		<div class="requests-card">
			<h2 class="requests-title">Access Requests</h2>
			<div class="request-item" v-for="(request, index) in requests" :key="index">
				<span>{{ request.name }}</span>
				<button @click="approveRequest(index)">Approve</button>
				<button @click="rejectRequest(index)">Reject</button>
			</div>
		</div>
	</div>
</template>

<script>
import axios from '../../../../axios';

export default {
	data() {
		return {
			research_id: this.$route.params.id,
			users: [
			],
			roles: ['Admin', 'Write', 'Read', 'Ready'],
			requests: [
				{ name: 'User4' },
				{ name: 'User5' }
			],
			originalRoles: {

			}
		};
	},
	
	async created() {
		try {
			const data = await axios.get(`${process.env.VUE_APP_API_URL}/research/${this.research_id}/role`);
			console.log(data.data);
			const a = []
			data.data.roles.forEach(d => {
				console.log(d)
				const temp = {
					name: d.user, 
					id: d.user_id,
					role: d.role
				};
				a.push(temp);
			});
			this.users = a;
		} catch(error) {
			console.error(error);
		}
	},
	methods: {
		storeOriginalRole(user) {
			this.originalRoles[user.id] =  user.role
		},
		removeUser(index) {
			this.users.splice(index, 1);
		},
		submitChanges() {
			console.log('Changes submitted', this.users);
		},
		approveRequest(index) {
			const approvedUser = this.requests.splice(index, 1)[0];
			this.users.push({ name: approvedUser.name, role: 'Reader' }); // 기본 역할은 Reader로 설정
		},
		rejectRequest(index) {
			this.requests.splice(index, 1);
		},
		async updateUserRole(user, index){
			console.log(user)
			try{
				await axios.put(`${process.env.VUE_APP_API_URL}/research/${this.research_id}/role/update`,  {
					user_id: user.id,
					new_role: user.role
				});
			} catch(error){
				this.users[index] = { ...user, role: this.originalRoles[user.id] };
			}
		}
	}
};
</script>

<style scoped>
.permissions-container {
	display: flex;
	justify-content: space-around;
	align-items: flex-start;
	padding: 2rem;
	background-color: #f5f5f5;
	height: 100vh;
}

.permissions-card, .requests-card {
	background: white;
	padding: 2rem;
	border-radius: 8px;
	box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
	width: 45%;
}

.permissions-title, .requests-title {
	margin-bottom: 1.5rem;
	text-align: center;
	color: #333;
}

.user-list, .request-item {
	display: flex;
	flex-direction: column;
	margin-bottom: 1rem;
}

.user-item, .request-item {
	display: flex;
	align-items: center;
	margin-bottom: 1rem;
}

.user-item span, .request-item span {
	margin-right: 1rem;
	flex: 1;
}

.user-item select, .user-item button, .request-item button {
	margin-left: 0.5rem;
}

.submit-button {
	width: 100%;
	padding: 0.75rem;
	background-color: #007bff;
	color: white;
	border: none;
	border-radius: 4px;
	cursor: pointer;
	font-size: 1rem;
}

.submit-button:hover {
	background-color: #0056b3;
}
</style>
