import { createStore } from 'vuex';
import createPersistedState from 'vuex-persistedstate';
import auth from './modules/auth';
import category from './modules/category';


const store = createStore({
  plugins: [createPersistedState(
    {
      paths: ['auth']
    }
  )],
  modules: {
    auth,
    category
  }
});


export default store;