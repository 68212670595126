<template>
  <div class="home-news">
    <div class="content">
      <br>
      <br>
      <br>
      <span class="title">{{title}}</span>
      <div>
        <span class="man">{{man}}</span>
      </div>
      <div class="line"></div>
      <div class="description">
        <p v-html="description">
        </p>
        <a href="/about"><button class="read-more">더 읽어보기</button></a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
    name: "HomeNews",
    data() {
        return {
            title: "We are made of starstuff",
            man: "- Carl Sagan -",
            description: `NUPS는 유동호가 어쩌고 저쩌고 Lorem ipsum dolor sit amet, consectetur adipiscing elit.
          <br>
          Vestibulum pretium tellus sed justo sollicitudin, ut venenatis orci congue. Nulla facilisi.
          Nunc vestibulum magna vitae massa consequat, ac gravida elit posuere. Donec massa nulla,
          convallis vel porta quis, ultrices fringilla eros. Mauris pharetra ante id erat pretium convallis.
          <br>
          Lorem ipsum dolor sit amet, 김진우 화이팅 consectetur adipiscing elit. 
          <br>
          Donec imperdiet egestas fermentum.
          Suspendisse tristique placerat ex sit amet`
        };
    },
    methods:{
        navigateTo(link){
            this.$router.push(link);
        }
    }
}
</script>

<style scoped>
.man{
    font-family: Times New Roman;
	font-style: normal;
	font-weight: bold;
	font-size: 28px;
	color: rgba(246,246,246,1);
}
.title{
	white-space: nowrap;
	text-align: center;
	font-family: Times New Roman;
	font-style: normal;
	font-weight: bold;
	font-size: 60px;
	color: rgba(235,235,235,1);
}
.home-news {
    position: relative;
    overflow: hidden;
    z-index: 0;
    width: 100%;
    height: 800px;
    /* 배경 이미지 설정 */
    background: url('@/assets/Mask_Group_1.png') no-repeat center center;
    background-size: cover;
}

.home-news::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 30%; /* 그라데이션의 높이 조정 */
    background: linear-gradient(to bottom, rgba(253, 0, 0, 0) 0%, rgba(255, 255, 255, 1) 100%);
    pointer-events: none;
    z-index: 1;
}

.content {
    position: relative;
    z-index: 2;
    color: white;
    text-align: center;
    padding: 20px;
}

.line {
    width: 4px;
    height: 104px;
    background: white;
    margin: 20px auto;
    stroke-width: 4px;
	stroke-linejoin: miter;
	stroke-linecap: round;
	stroke-miterlimit: 4;
	fill: transparent;
	shape-rendering: auto;
    border-top-left-radius: 10px 10px;
    border-top-right-radius: 10px 10px;
    border-bottom-left-radius: 10px 10px;
    border-bottom-right-radius: 10px 10px;
}



.description  p{
	text-align: left;
	font-family: Noto Sans CJK KR;
	font-style: normal;
	font-weight: normal;
	font-size: 20px;
	color: rgba(203,203,203,1);
}
.description {
    background: rgba(0, 0, 0, 0.6);
    padding: 20px;
    margin: 20px auto;
    width: 60%;
    position: relative; /* 추가 */
}

.read-more {
	background: rgba(23,69,133,0.6);
    color: black;
    border: none;
    width: 140px;
	height: 50px;
    cursor: pointer;
    position: absolute; /* 추가 */
    bottom: -55px;
    right: 0; /* 오른쪽 끝에 붙이기 */
    font-family: Noto Sans CJK KR;
	font-style: normal;
	font-weight: normal;
	font-size: 20px;
	color: rgba(235,235,235,1);
}
</style>
