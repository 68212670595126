export default {
    setUser(state, payload) {
        state.token = payload.token;
        state.userId = payload.userId;
        state.tokenExpiration = payload.tokenExpiration;
        state.userInfo = payload.userInfo;
    },
    setUserNull(state) {
        state.token = null;
        state.userId = null;
        state.tokenExpiration = null;
        state.userInfo = null;
    },
    setTokenValidate(state, payload) {
        state.tokenValidate = payload.valid;
        console.log(state)
    }
}