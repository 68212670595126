<template>
<div class="about-container">
    <home-member :all="true"></home-member>
</div>
</template>

<script>
import HomeMember from '../../components/home/HomeMember.vue'
export default {
    components:{
        HomeMember
    }
}
</script>

<style>

</style>