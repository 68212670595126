<template>
  <div>
    <div v-for="link in categoryLinks" :key="link.link" class="detail-item">
        <router-link class="custom-link" :to="link.link" active-class="active-link">{{link.title}}</router-link>
    </div>
  </div>
</template>

<script>
export default {
    data(){
        return {
            categoryLinks: [
                {
                    link: '/kaist/login',
                    title: '로그인',
                },
                {
                    link: '/kaist/register',
                    title: '회원가입'
                },
                {
                    link: '/kaist/research/list',
                    title: '연구과제 목록'
                },
                {
                    link: '/kaist/research/kaist/board/write',
                    title: '연구과제 제의하기'
                },
                {
                    link: '/kaist/board/write?category=notice',
                    title: '공지사항 쓰기'
                },
                {
                    link: '/kaist/board/write?category=media',
                    title: '뉴스 쓰기'
                },
                {
                    link: '/kaist/board/write?category=news',
                    title: '새소식 쓰기'
                }
            ]
        }
    }
}
</script>

<style>

</style>