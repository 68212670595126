<template>
    <div>
        <h2>Edit Research</h2>
        <div v-if="loading">Loading...</div>
        <div v-else>
            <CommonForm :research="research" :currentImage="currentImage" :buttonText="'Update'" :submitForm="updateResearch" />
        </div>
    </div>
</template>

<script>
import axios from '../../../../axios'
import CommonForm from '../../../components/kaist/research/CommonForm.vue';

export default {
    components: { CommonForm },
    data() {
        return {
            research_id: this.$route.params.id,
            research: {
                name: '',
                color: '#ffffff',
                introduction: '',
                image: null,
                id: 0,
            },
            currentImage: null,
            loading: true,
        };
    },
    methods: {
        fetchResearch() {
            axios.get(`${process.env.VUE_APP_API_URL}/research/${this.research_id}/about`)
            .then(response => {
                this.research = response.data;
                this.currentImage = `${process.env.VUE_APP_API_URL}/research${this.research.image_url}`
                this.loading = false;
            })
            .catch(error => {
                console.error('Error fetching research:', error);
                this.loading = false;
            });
        },
        updateResearch(data) {
            this.loading = true;
            const formData = new FormData();
            formData.append('name', data.name);
            formData.append('color', data.color);
            formData.append('introduction', data.introduction);
            if (data.new_image) {
                formData.append('image', data.new_image);
            }
            axios.put(`${process.env.VUE_APP_API_URL}/research/${this.research_id}/about`, formData,{
                headers: {
                    'Content-Type': 'multipart/form-data',
                    'Authorization': `Bearer ${localStorage.getItem('token')}`,
            },})
            .then((data) => {
                alert('Research updated successfully');
                this.loading = false;
                this.currentImage = `${process.env.VUE_APP_API_URL}/research${data.data.image_url}`
                this.fetchResearch();
            })
            .catch(error => {
                console.error('Error updating research:', error);
                alert('Failed to update research');
                this.loading = false;
            });
        }
    },
    created() {
        this.fetchResearch();
    }
};
</script>
