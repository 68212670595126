<template>
<div class="info-board-container">
    <list-common :posts="infos" :loading="loading"></list-common>
    <pagination-common :totalPages="totalPages"></pagination-common>
</div>
</template>

<script>
import ListCommon from '../../components/common/board/ListCommon.vue'; 
import PaginationCommon from '../../components/common/board/PaginationCommon.vue'; 
export default {
    components:{
        ListCommon,
        PaginationCommon,
    },
}
</script>

<style>

</style>