<template>
<div class="info-board-container">
    <list-common :posts="infos" :loading="loading"></list-common>
    <pagination-common :totalPages="totalPages"></pagination-common>
</div>
</template>

<script>
import ListCommon from '../../components/common/board/ListCommon.vue'; 
import PaginationCommon from '../../components/common/board/PaginationCommon.vue'; 
import axios from 'axios';
export default {
    components:{
        ListCommon,
        PaginationCommon,
    },
	data() {
		return {
			infos: [
            ],
            postsPerPage: this.$route.query.perPage || 10,
            order: this.$route.query.order || 'desc', //desc: 최신순, asc: 오래된순
            category: "News",
            totalPosts: 0,
            loading: false
		};
	},
    watch: {
        $route: 'getData'
    },
    computed: {
        totalPages() {
            return Math.ceil(this.totalPosts / this.postsPerPage)
        },
        page() {
            return this.$route.query.page || 1;
        },
    },
    methods: {
        async getData(){
            this.loading = true;
            try{
                const data = await axios.get(`${process.env.VUE_APP_API_URL}/board/post`, {
                    params: {
                        category: this.category,
                        postsPerPage: this.postsPerPage,
                        order: this.order,
                        page: this.page,
                    },
                });
                const a = []
                data.data.posts.forEach(d => {
                    const temp = { id: d.id,  categoryLink: d.category, category: {
                        name: "넙스 뉴스",
                        id: '001-001',
                        color: "#c16565"
                    }, content: d.title, date:d.post_date };
                    a.push(temp)
                });
                this.totalPosts = data.data.totalPosts;
                this.infos = a
            } catch(error){
                console.error(error);
            }
            this.loading = false;
        },
        // changeItemsPerPage() {
		// 	this.page = 1;
		// },
    },  
    async created() {
        await this.getData();
    }
}
</script>

<style scoped>

</style>