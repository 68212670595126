<template>
<div>
    해당 페이지가 없습니다.
</div>  
</template>

<script>
export default {

}
</script>

<style>

</style>