import axios from '../../../../axios'

export default {
  async signIn(context, payload) {
    try {
        const response = await axios.post(`${process.env.VUE_APP_API_URL}/auth/login`, {
          email: payload.email,
          password: payload.password
        });
        const authData = {
          token: response.data.access_token,
          userId: response.data.user_id,
          tokenExpiration : response.data.expires_in,
          userInfo: response.data.userInfo
        };
        console.log("hihi", authData)
        context.commit('setUser', authData);
        localStorage.setItem('auth', JSON.stringify(authData));
        return {};
      } catch (error) {
        return {error};
      }
  },
  async signUp(_context, payload) {
      try {
        await axios.post(`${process.env.VUE_APP_API_URL}/auth/register`, {
          kr_name: payload.kr_name,
          eng_name: payload.eng_name,
          student_id: payload.studentId,
          email: payload.email,
          password: payload.password,
          type: payload.type
        });
        return {};
      } catch (error) {
        console.error('Registration failed', error);
        return {error};
      }
  },
  async checkTokenValid({getters, state, commit, dispatch}){
    if(!getters.isAuthentication){
      dispatch('logout');
      commit('setTokenValidate', {
        valid: false
      });
    }
    try {
      const res = await axios.post(`${process.env.VUE_APP_API_URL}/auth/isValid`, {
        "userId": state.userId
      });
      console.log(res.data)
      if(res.data.isValid){
        commit('setTokenValidate', {
          valid: true
        });
      } else {
        dispatch('logout');
        commit('setTokenValidate', {
          valid: false
        });
      }
    } catch {
      dispatch('logout');
      commit('setTokenValidate', {
        valid: false
      });
    }
  },
  autoLogin() {
    // try {
    //   const { token, userId, tokenExpiration } = JSON.parse(localStorage.getItem('auth'));
    //   if (!!token && !!userId && !!tokenExpiration) {
    //     context.commit('setUser',{
    //       token : token,
    //       userId : userId,
    //       tokenExpiration : tokenExpiration
    //     })
    //   } else {
    //     console.log("bye...")
    //     context.commit('setUserNull');
    //   }
    // } catch {
    //   context.commit('setUserNull');
    // }
  },
  logout(context){
    context.commit('setUserNull');
    localStorage.setItem('auth', JSON.stringify({
      token : null,
      userId : null,
      tokenExpiration : null
    }));
  }
}