<template>
    <div>
        <h2>Create New Research</h2>
        <CommonForm :research="research" :buttonText="'Create'" :submitForm="createResearch" />
    </div>
</template>

<script>
import CommonForm from '../../../components/kaist/research/CommonForm.vue';
import axios from '../../../../axios'


export default {
    components: { CommonForm },
    data() {
        return {
            research: {
                name: '',
                color: '#ffffff',
                introduction: '',
                image: null,
            },
            loading: false,
        };
    },
    methods: {
        createResearch(data) {
            this.loading = true;
            const formData = new FormData();
            formData.append('name', data.name);
            formData.append('color', data.color);
            formData.append('introduction', data.introduction);
            if (!data.new_image) {
                console.error("no image");
            }
            formData.append('image', data.new_image);
            axios.post(`${process.env.VUE_APP_API_URL}/research/upload/about`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    'Authorization': `Bearer ${localStorage.getItem('token')}`,
            },})
                .then(() => {
                    alert('Research created successfully');
                    this.loading = false;
                    this.$router.push('/kaist');
                })
                .catch(error => {
                    console.error('Error creating research:', error);
                    alert('Failed to create research');
                    this.loading = false;
                });
        }
    }
};
</script>
