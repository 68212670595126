import { createRouter, createWebHistory } from 'vue-router'
import store from '../store'

import HomeView from '../views/HomeView.vue'

// import Notice from '../views/news/NewsNotice.vue';
import NewsBoard from '../views/news/NewsNoticeBoard.vue';
import Info from '../views/news/NewsInfo.vue';
import Media from '../views/news/NewsMedia.vue';
import NewsPost from '../views/news/NewsPost.vue';

import Detail from '../views/about/AboutDetail.vue';
import Symbol from '../views/about/AboutSymbol.vue';
import Intro from '../views/about/AboutIntro.vue';
import History from '../views/about/AboutHistory.vue';
import Members from '../views/about/AboutMembers.vue';

import Academy from '../views/event/EventAcademy.vue';
import Seminar from '../views/event/EventSeminar.vue';
import Paper from '../views/event/EventPaper.vue';
import Lecture from '../views/event/EventLecture.vue';

import Manage from '../views/kaist/research/ResearchManage.vue';
import ManageAbout from '../views/kaist/research/ManageAbout.vue';
import ManageRole from '../views/kaist/research/RoleView.vue';
import UploadAbout from '../views/kaist/research/UploadAbout.vue';

import KaistHome from '../views/kaist/KaistHome.vue';


import ResearchList from '../views/research/ResearchList.vue'
import ResearchPost from '../views/research/ResearchPost.vue'
import ResearchDetail from '../views/research/ResearchDetail.vue'

import AdminResearch from '../views/kaist/admin/AdminResearch.vue';
import AdminMember from '../views/kaist/admin/AdminMember.vue';
import AdminAbout from '../views/kaist/admin/AdminAbout.vue';

import FaqView from '../views/FaqView.vue'

import NotFound from '../views/NotFound.vue'




const routes = [
  {
    path: '/',
    redirect: '/p'
  },
  {
    path: '/p',
    name: 'home',
    component: () => import(/* webpackChunkName: "about" */ '../views/OutView.vue'),
    children: [
      {
        path: '',
        name: 'home',
        component: HomeView
      },
      {
        path: 'news',
        component: () => import(/* webpackChunkName: "about" */ '../views/InnerView.vue'),
        children: [
          {path: '', redirect: '/p/news/notice'},
          {
            path: 'notice',
            redirect: '/p/news/notice/board'
          },
          {
            path: 'info',
            redirect: '/p/news/info/board'
          },
          {
            path: 'media',
            redirect: '/p/news/media/board'
          },
          { 
            path: 'notice/board',
            component: NewsBoard,
          },
          { path: 'info/board', component: Info },
          { path: 'media/board', component: Media },
          { path: ':category/post/:id', component: NewsPost }
        ]
      },
      {
        path: 'about',
        component: () => import(/* webpackChunkName: "about" */ '../views/InnerView.vue'),
        children: [
          {path: '', redirect: '/p/about/detail'},
          { path: 'detail', component: Detail },
          { path: 'symbol', component: Symbol },
          { path: 'intro', component: Intro },
          { path: 'history', component: History },
          { path: 'members', component: Members }
        ]
      },
      {
        path: 'event',
        component: () => import(/* webpackChunkName: "about" */ '../views/InnerView.vue'),
        children: [
          { path: '', redirect: '/p/event/academy' },
          { path: 'academy', component: Academy },
          { path: 'seminar', component: Seminar },
          { path: 'paper', component: Paper },
          { path: 'lecture', component: Lecture }
        ]
      }, 
      {
        path: 'research',
        component: () => import(/* webpackChunkName: "about" */ '../views/InnerView.vue'),
        children: [
          { path: '', redirect: '/p/research/list'},
          { path: 'list', component: ResearchList },
          { path: 'detail/:id', component: ResearchDetail },
          { path: 'post', component: ResearchPost }
        ]
      },
      {
        path: 'faq',
        component: () => import(/* webpackChunkName: "about" */ '../views/InnerView.vue'),
        children: [
          { path: '', redirect: "/p/faq/contact"},
          {
            path: 'contact',
            component: FaqView
          }
        ]
      },
      {
        path: '/:pathMatch(.*)*',
        component: NotFound
      }
    ]
  },
  {
    path: '/kaist',
    name: 'kaist',
    component: () => import(/* webpackChunkName: "about" */ '../views/kaist/KaistView.vue'),
    meta: { requireAuth: true},
    children: [
      {
        path: '',
        component: KaistHome
      },
      {
        path: 'research/list',
        component: ResearchList
      },
      {
        path: 'research/upload/about',
        component: UploadAbout,
      },
      {
        path: 'admin/research',
        component: AdminResearch
      },
      {
        path: 'admin/member',
        component: AdminMember
      },
      {
        path: 'admin/about',
        component: AdminAbout
      },
      {
        path: 'research/:id',
        name: 'kaist_research',
        component: () => import(/* webpackChunkName: "about" */ '../views/kaist/research/BoardView.vue'),
        children: [
        ]
      },
      {
        path: 'research/:id/manage',
        name: 'research_manage',
        component: Manage,
        children: [
          {path: 'role', component: ManageRole},
          {path: 'about', component: ManageAbout}
        ],
        // component: () => import(/* webpackChunkName: "about" */ '../views/kaist/research/RoleView.vue')
      },
      {
        path: 'login',
        name: 'login',
        meta: { requireUnAuth: true},
        component: () => import(/* webpackChunkName: "about" */ '../views/kaist/auth/LoginView.vue')
      },
      {
        path: 'register',
        name: 'register',
        meta: { requireUnAuth: true},
        component: () => import(/* webpackChunkName: "about" */ '../views/kaist/auth/RegisterView.vue')
      },
      {
        path: 'board/write',
        name: 'write',
        component: () => import(/* webpackChunkName: "about" */ '../views/kaist/board/WriteView.vue')
      },
      {
        path: '/:pathMatch(.*)*',
        component: NotFound
      }
    ]
  },

]
const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})


router.beforeEach(async (to, from, next) => {
  const isAuthenticated = store.getters['auth/isAuthentication'];
  const isTokenValid = store.getters['auth/isValid'];
  if (to.query.category) {
    to.meta.category = to.query.category;
  } else {
    to.meta.category = 'Notice'; // 삭제해야함
  }
  if (to.matched.some(record => record.meta.requireAuth) && !to.matched.some(record => record.meta.requireUnAuth)) {
    if (isAuthenticated) {
      next();
    } else {
      next('/kaist/login');
    }
  } else if (to.matched.some(record => record.meta.requireUnAuth)) {
    await store.dispatch('auth/checkTokenValid');
    if (!isTokenValid) {
      next();
    } else {
      next(from.fullPath);
    }
  } else {
    next();
  }
});


// router.beforeEach(async (to, from, next) => {
//   if (to.matched.some(record => record.meta.requiresAuth)) {
//     if (!loggedIn) {
//       next({ name: 'login' })
//     } else {
//       const requiredRole = to.meta.requiredRole
//       const researchId = to.params.research_id || to.meta.researchId

//       try {
//         const response = await axios.post('http://localhost:5000/check_permission', {
//           research_id: researchId,
//           required_role: requiredRole
//         }, {
//           headers: {
//             Authorization: `Bearer ${token}`
//           }
//         })
        
//         if (response.data.message === 'Permission granted') {
//           next()
//         } else {
//           next({ name: 'home' })
//         }
//       } catch (error) {
//         next({ name: 'home' })
//       }
//     }
//   } else if (to.matched.some(record => record.meta.requiresGuest)) {
//     if (loggedIn) {
//       next({ name: 'home' })
//     } else {
//       next()
//     }
//   } else {
//     next()
//   }
//   
//   next();
// })

export default router
