<template>
<div>
    <div v-if="loading">
            Loading...
    </div>
    <ul v-else class="list">
        <board-box-common v-for="(project, index) in posts" :key="index" :project="project"></board-box-common>
    </ul>
</div>
</template>

<script>
import BoardBoxCommon from './BoardBoxCommon.vue'; 

export default {
    props: {
        posts: {
            type: Array,
            required: true
        },
        loading: {
            type: Boolean,
            required: true
        },
        poastsPerPage: {
            type: Number
        }
    },
    components:{
        BoardBoxCommon,
    },
}
</script>

<style>
.list {
	list-style: none;
	padding: 0;
}
</style>