<template>
  <common-post>
    <div class="post-container">
        <div class="post-header">
        <div class="post-tag">{{tag}}</div>
        <div class="post-title-container">
            <div v-if="loading" class="post-title">loading...</div>
            <div v-else class="post-title">{{title}}</div>
            <div class="post-meta">
            <span>작성자: {{writer}}</span>
            <span class="post-date">{{post_date}}</span>
            </div>
        </div>
            <svg class="Line_41" viewBox="0 0 4000 2">
                <path id="Line_41" d="M 0 0 L 4000 0">
                </path>
            </svg>
        </div>
        <div class="post-content">
            <p v-if="loading">
                loading...
            </p>
            <p v-else v-html="content">
            </p>
        </div>
        <div class="post-footer">
        <button @click="goBack" class="back-button">뒤로가기</button>
        </div>
    </div>
  </common-post>
</template>

<script>
import axios from 'axios'
export default {
  name: "PostDetail",
  components: {
  },
  data() {
    return {
        category: "",
        tag: "",
        content: "",
        id: 0,
        post_date: "",
        title: "",
        user_id: 1,
        writer: "",
    }
  },
  methods: {
    goBack() {
      this.$router.go(-1);
    },
    async getData(){
        this.loading = true;
        try{
            const response = await axios.get(`${process.env.VUE_APP_API_URL}/board/post/${this.$route.params.id}`);
            const data = response.data;
            this.category = data.category;
            this.content = data.content;
            this.id = data.id;
            this.post_date = data.post_date;
            this.title = data.title;
            this.user_id = data.user_id;
            this.writer = data.user_name;
        } catch(error){
            console.error(error);
        }
        this.loading = false;
    },
  },
  created() {
    this.getData();
  }
};
</script>

<style lang="css" scoped src='@/assets/styles/CommonPost.css'></style>
<style scoped>
</style>
