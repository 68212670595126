<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>
import { mapActions } from 'vuex';


export default {
  name: 'App',
  data() {
  },
  computed: {
  },
  created() {
    this.$store.dispatch('auth/autoLogin')
  },
  mounted() {
    this.applyDynamicStyles();  
    window.addEventListener('resize', this.applyDynamicStyles);
    window.addEventListener('load', this.applyDynamicStyles);
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.applyDynamicStyles);
    window.removeEventListener('load', this.applyDynamicStyles);
  },
  methods: {
    ...mapActions('category', ['updateCurrentPath']),
    applyDynamicStyles() {
      const width = window.innerWidth;
      const s_width = screen.width;
      const body = document.body;
      if (width < 1300 * (s_width / 1920)) {
        body.classList.add('small-screen');
      } else {
        body.classList.remove('small-screen');
      }
      if (width < 1100 * (s_width / 1920)) {
        body.classList.add('small-nav');
      } else {
        body.classList.remove('small-nav');
      }
      if (s_width != 1920) {
        document.body.style.zoom = (s_width / 1920) * 100 + '%';
      }
    },
  },
  watch: {
      '$route'(to) {
        const category = to.path.split('?')[0].split('/').slice(1);
        this.updateCurrentPath(category);
      }
    },
};


</script>

<style>
*{
  margin:0;
}
.post-img {
  width: 80%;
}
</style>
