<template>
    <div class="research-cards-container">
        <div class="research-card" v-for="research in researchList" :key="research.id">
            <img :src="research.image_url" alt="Research Image" class="research-image" />
            <svg class="Line_45" viewBox="0 0 515 1">
                <path id="Line_45" d="M 0 0 L 515 0">
                </path>
            </svg>
            <div class="research-info">
                <div class="title">{{ research.name }}</div>
                <div class="participants">{{research.participants.join(', ')}}</div>
                <div class="read-more">
                    <button @click="viewDetails(research.id)" class="read-more-btn">더 읽어보기</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios';

export default {
    data() {
        return {
            researchList: []
        };
    },
    created() {
        this.fetchResearchList();
    },
    methods: {
        fetchResearchList() {
            axios.get(`${process.env.VUE_APP_API_URL}/research/list`)
                .then(response => {
                    this.researchList = response.data.map(research => {
                        if (research.image_url) {
                            research.image_url = `${process.env.VUE_APP_API_URL}/research${research.image_url}`;
                        }
                        research.participants = research.roles.map(role => {
                            return role.user_name
                        })
                        return research;
                    });
                    console.log(response.data)
                })
                .catch(error => {
                    console.error('Error fetching research:', error);
                });
        },
        viewDetails(id) {
            this.$router.push(`detail/${id}`);
        }
    }
}
</script>

<style scoped>
.research-cards-container {
    margin-top: 40px;
    display: flex;
    flex-wrap: wrap;
    gap: 80px;
    padding: 20px;
    justify-content: center;
    align-items: center;
}

.research-card {
    border: 1px solid #ddd;
    position: relative;
    border-radius: 8px;
    padding: 21px;
    overflow: hidden;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    width: 460px;
    height: 510px;
}


.title{
    max-height: 90px;
    width: 100%;
    font: normal normal bold 20px/29px Noto Sans CJK KR;
    color: #191919;
    text-overflow : ellipsis
}

.participants {
    margin-top: 10px;
    width: 90%;
    white-space: nowrap;
    text-align: left;
    font: italic normal normal 16px/18px Times New Roman;
    letter-spacing: 0px;
    color: #191919;
}

.research-image {
    width: 100%;
    height: 262px;
    object-fit: cover;
}

.read-more {
    display: flex;
    position: absolute;
    bottom: 30px;
    justify-content: center; 
    position: absolute;
    bottom: 30px;
    left: 50%;
    transform: translateX(-50%);
}

.read-more-btn {
    border: none;
    padding: 7px 12px;
    cursor: pointer;
    text-align: center;
    background: #D9073F 0% 0% no-repeat padding-box;
    border-radius: 25px;
    font: normal normal bold 18px/26px Noto Sans CJK KR;
    color: #EBEBEB;
}

.read-more-btn:hover {
    background-color: #e04e4f;
}

#Line_45 {
    fill: transparent;
    stroke: rgba(112, 112, 112, 1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}

.Line_45 {
    overflow: visible;
    position: relative;
    top: -7px;
    left: -5px;
    width: 470px;
    height: 1px;
    transform: matrix(1, 0, 0, 1, 0, 0);
}
</style>
