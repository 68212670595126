<template>
<div class="pagination-container">
    <div class="pagination">
        <div :disabled="page == 1" @click="prevPage" class="prev-btn">〈</div>
        <div class="button-container">
            <div @click="onClickPageBtn(pageNumber)" class="page-btn" v-for="pageNumber in pageNumbers" :key="pageNumber" :class="{active: pageNumber == page}">
                {{pageNumber}}
            </div>
        </div>
        <div :disabled="page === totalPages" @click="nextPage" class="next-btn">〉</div>
    </div>
</div>
</template>

<script>
export default {
    props: {
        totalPages: {
            type: Number,
            required: true
        },
    },
    computed: {
        tensPage() {
            const currentPage = this.page;
            return Math.floor((currentPage-1) / 10);
        },
        pageNumbers() {
            const totalPages = this.totalPages;
            const result = [];
            const a = this.tensPage;
            const maxPage = Math.min(totalPages - a*10, 10);
            for(let i=0; i < maxPage; i++){
                result.push(a*10+i+1);
            }
            return result;
        },
        page() {
            return this.$route.query.page || 1;
        },
    },
    methods: {
        onClickPageBtn(pageNumber){
           this.$router.push({ query: { ...this.$route.query, page: Number(pageNumber)} });
        },
        nextPage() {
			if (this.totalPages - this.tensPage*10 > 10) {
				this.$router.push({ query: { ...this.$route.query, page: (this.tensPage+1)*10 +1} });
			}
		},
		prevPage() {
			if (this.tensPage > 0) {
				this.$router.push({ query: { ...this.$route.query, page: (this.tensPage-1)*10 +1 } });
			}
		},
    },
}
</script>

<style>

.prev-btn, .next-btn {
    width: 27px;
    height: 27px;
    text-align: center;
    font-weight: bold;
    font-size: 20px;
    color: #191919;
}
.prev-btn:hover, .next-btn:hover {
    background: #F5F5F5 0% 0% no-repeat padding-box;
}
.pagination-container{
    width: 100%;
}
.pagination {
    margin: 0px auto;
    width: fit-content;
}
.pagination, .button-container {
    display: flex;
}
.page-btn {
    width: 27px;
    height: 27px;
    line-height: 27px;
    text-align: center;
    font: normal normal bold 20px/24px Roboto;
    letter-spacing: 0px;
    color: #191919;
}
.page-btn:first-child {
    margin-left: 13px;
}
.page-btn:last-child {
    margin-right: 13px;
}
.page-btn:hover {
    background: #F5F5F5 0% 0% no-repeat padding-box;
}
.page-btn.active {
    background: #F5F5F5 0% 0% no-repeat padding-box;
    border: 1px solid #707070;
}
.pagination-container {
    margin-bottom: 35px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-use-select: none;
    user-select: none;
}
</style>