<template>
    <li class="item" @click="board_clicked(project.id)">
        <div class="category">
            <div class="category-name" :style="'color:'+project.category.color">
                <span>{{ project.category.name }}</span>
            </div>
            <div class="category-id">
                <span>{{ project.category.id }}</span>
            </div>
        </div>
        <svg class="Line_3" viewBox="0 0 1.5 54">
            <path id="Line_3" d="M 0 0 L 0 54">
            </path>
        </svg>
        <div class="content-container">
            <div >
                <span class="content">{{ project.content }}</span>
            </div>
            <div>
                <span class="date">{{ project.date }}</span>
            </div>
        </div>
        <div id="plus-sign-in-a-circle-svgrepo-">
            <svg class="Path_38" viewBox="0 0 20 20">
                <path id="Path_38" d="M 10 0 C 4.485942840576172 0 0 4.485942840576172 0 10 C 0 15.51301383972168 4.485942840576172 20 10 20 C 15.51301383972168 20 20 15.51301193237305 20 10 C 20 4.485942840576172 15.51301383972168 0 10 0 Z M 10 17.91299247741699 C 5.636638641357422 17.91299247741699 2.086484909057617 14.36179733276367 2.086484909057617 9.999479293823242 C 2.086484909057617 5.636116981506348 5.636638641357422 2.085963487625122 10 2.085963487625122 C 14.36336040496826 2.085963487625122 17.91351509094238 5.636116981506348 17.91351509094238 9.999479293823242 C 17.91351509094238 14.36179733276367 14.36336040496826 17.91299247741699 10 17.91299247741699 Z M 16.0946216583252 10 C 16.0946216583252 10.86380481719971 15.39356231689453 11.56486320495605 14.52975845336914 11.56486320495605 L 11.5643424987793 11.56486320495605 L 11.5643424987793 14.53028011322021 C 11.5643424987793 15.39356231689453 10.86276245117188 16.09514427185059 9.999479293823242 16.09514427185059 C 9.135151863098145 16.09514427185059 8.434615135192871 15.39356231689453 8.434615135192871 14.53028011322021 L 8.434615135192871 11.56486320495605 L 5.469719886779785 11.56486320495605 C 4.605393886566162 11.56486320495605 3.904856204986572 10.86380481719971 3.904856204986572 10 C 3.904856204986572 9.135673522949219 4.605393886566162 8.435135841369629 5.469719886779785 8.435135841369629 L 8.434615135192871 8.435135841369629 L 8.434615135192871 5.470762729644775 C 8.434615135192871 4.606436252593994 9.135151863098145 3.905899047851562 9.999479293823242 3.905899047851562 C 10.86276245117188 3.905899047851562 11.5643424987793 4.606436252593994 11.5643424987793 5.470762729644775 L 11.5643424987793 8.435135841369629 L 14.52975845336914 8.435135841369629 C 15.39408397674561 8.435135841369629 16.0946216583252 9.135673522949219 16.0946216583252 10 Z">
                </path>
            </svg>
        </div>
    </li>
</template>

<script>
import {mapGetters} from 'vuex'
export default {
    name: "BoardBoxCommon",
    props: {
        project: {
            type: Object,
            required: true
        }
    },
    computed: {
        ...mapGetters('category', ['categories']),
        link() {
            let target = this.project.categoryLink.toLowerCase();
            const categories = this.categories;
            if(target == "news"){
                target = "info"
            }
            console.log(target)

            let result = '';
            for (const [categoryKey, categoryValue] of Object.entries(categories)) {
                if (categoryKey === target) {
                    result+=categoryKey+'/'
                    return result;
                }
                if (categoryValue.detail) {
                    for (const detailItem of categoryValue.detail) {
                        if (detailItem.link === target) {
                            result+=categoryKey + '/' + detailItem.link+'/'
                            return result;
                        }
                    }
                }
            }
            return result;
        }
    },
    methods: {
        board_clicked (id){
            this.$router.push('/p/'+this.link+'post/'+id)
        }
    },
    created(){
    }
}
</script>

<style scoped>
.item {
    display: flex;
    padding: 10px 0;
    border-bottom: 1px solid #ddd;
    width: 94%;
    margin: 12px auto;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px;
    position: relative;
    background: #F5F5F5 0% 0% no-repeat padding-box;
    box-shadow: inset 0px 3px 6px #00000029;
    border-radius: 12px;
    opacity: 1;
}
.item:last-child {
    border-bottom: none;
    margin-bottom: 80px;
}

.category-name {
    font-family: Noto Sans CJK KR;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    color: rgba(235,235,235,1);
    margin-bottom: 2px;
}
.category-id {
    font-family: Noto Sans CJK KR;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    color: rgba(112,112,112,1);
}
.content, .date {
    margin: 5px 0px;
}
.date {
    font-family: Noto Sans CJK KR;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    color: rgba(112,112,112,1);
}
.content {
    font-family: Noto Sans CJK KR;
    font-style: normal;
    font-weight: bold;
    font-size: 21px;
    color: rgba(25,25,25,1);
}
.content-container {
    width: 80%;
}
.content-container > div {
    white-space: nowrap;
    overflow: hidden;
    text-overflow : ellipsis
}
.category {
    margin: auto 0;
    margin-left: 14px;
    margin-right: 10px;
    white-space: nowrap;
    overflow: hidden;
}
#Line_3 {
	fill: transparent;
	stroke: rgba(112,112,112,1);
	stroke-width: 1.5px;
	stroke-linejoin: miter;
	stroke-linecap: butt;
	stroke-miterlimit: 4;
	shape-rendering: auto;
}
.Line_3 {
	overflow: visible;
	width: 1.5px;
	height: 50px;
	transform: matrix(1,0,0,1,0,0);
	margin-right: 10px;
}
#Path_38 {
    fill: rgba(112,112,112,1);
}
.Path_38 {
    overflow: visible;
    position: absolute;
    width: 20px;
    height: 20px;
    left: 0px;
    top: 0px;
    transform: matrix(1,0,0,1,0,0);
}

#plus-sign-in-a-circle-svgrepo- {
    width: 20px;
    height: 20px;
    overflow: visible;
    position: absolute;
    right: 20px;
    margin: auto 0px;
    top: 50%; 
    transform: translateY(-50%); 
}
</style>