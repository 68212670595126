<template>
	<home-common :code='0' :data="researchProjects" />
</template>

<script>
    import HomeCommon from './HomeCommon.vue'; 
    import axios from 'axios';


	export default {
        name: "HomeResearch",
        components:{
            HomeCommon,
        },
		data() {
			return {
				researchProjects: [
                    { id: 1, categoryLink: '', category: {
                    name: "연구 분류",
                    id: 123,
                    color: "#c16565"
                }, content: '' },
                { id: 1, categoryLink: '', category: {
                    name: "연구 분류",
                    id: 123,
                    color: "#c16565"
                }, content: '' },
                { id: 1, categoryLink: '', category: {
                    name: "연구 분류",
                    id: 123,
                    color: "#c16565"
                }, content: '' },
                { id: 1, categoryLink: '', category: {
                    name: "연구 분류",
                    id: 123,
                    color: "#c16565"
                }, content: '' }
				]
			};
		},
        async created() {
            try{
                const data = await axios.get(`${process.env.VUE_APP_API_URL}/board/post?category=Research&amount=4`);
                const a = []
                data.data.posts.forEach(d => {
                    const temp = { id: d.id, categoryLink: d.category, category: {
                        name: "연구 분류",
                        id: 123,
                        color: "#c16565"
                    }, content: d.title, date:d.post_date };
                    a.push(temp)
                });
                const b = a.length
                for(let i = 0; i < 4-b; i++){
                    a.push({ id: 1, categoryLink: '', category: {
                        name: "연구 분류",
                        id: 123,
                        color: "#c16565"
                    }, content: '' });
                }
                this.researchProjects = a
            } catch(error){
                console.error(error);
            }
        },
		methods: {
		},
	}
</script>

<style scoped>
</style>
