<template>
  <div>
    <button @click="updateAllData()" v-if="Object.keys(updatedMembers).length > 0">변경사항 저장하기</button>
    <div v-for="member in members" :key="member.id" class="member-item">
      <div @click="toggleDropdown(member.id)" class="member-summary">
        <span>{{ updatedMembers[member.id]?.kr_name || member.kr_name }} / {{updatedMembers[member.id]?.eng_name || member.eng_name }} / {{ updatedMembers[member.id]?.type || member.type }} / {{ updatedMembers[member.id]?.studentId || member.studentId }}</span>
        <button @click.stop="editMember(member.id)" v-if="!isEditing(member.id)">Edit</button>
      </div>
      <div v-if="isUserChanged(member.id)">
        <span class="error">Data has changed!</span>
        <button @click="syncWithUser(member.id)">동기화 하기</button>
      </div>
      <div v-if="selectedMemberId === member.id" class="member-dropdown">
        <div v-if="!isEditing(member.id)">
          <img v-if="member.image_url" :src="imgUrl(member.image_url)" alt="Member Photo">
          <p>{{ member.intro }}</p>
        </div>
      </div>
      <div v-if="isEditing(member.id)">
        <input v-model="updatedMembers[member.id].kr_name" />
        <input v-model="updatedMembers[member.id].eng_name" />
        <input v-model="updatedMembers[member.id].type" />
        <textarea v-model="updatedMembers[member.id].intro"></textarea>
        <input type="file" @change="onFileChange($event, member.id)" />
        <img v-if="previewImages[member.id]" :src="previewImages[member.id]" alt="Image Preview" />
        <button @click="saveChanges(member.id)">Save</button>
        <button @click="cancelEdit(member.id)">Cancel</button>
        <button v-if="isUserChanged(member.id)" @click="syncWithUser(member.id)">Sync</button>
      </div>
    </div>
    <button class="load-btn" @click="loadUsers()">User 불러오기</button>
    <div>
      <div v-for="user in users" :key="user.id" class="member-summary">
        <span>{{ user.kr_name }} / {{ user.eng_name }} / {{ user.type }} / {{ user.studentId }} </span>
        <button @click="addUserToMember(user.id)">추가하기</button>
      </div>
    </div>
    <div>
      <input ref="kr_name" placeholder="Korean Name" />
      <input ref="eng_name" placeholder="English Name" />
      <label for="type">Type: </label>
      <select ref="type" required>
        <option value="Stud">Student</option>
        <option value="Prof">Professor</option>
        <option value="Advisor">Advisory Professor</option>
        <option value="President">President</option>
        <option value="Vice">Vice President</option>
      </select>
      <input hidden ref="user_id"/>
      <textarea ref="intro" placeholder="Introduction"></textarea>
      <input type="file" ref="image" @change="onFileChange($event)" />
      <img v-if="newPreviewImage" :src="newPreviewImage" alt="New Image Preview" />
      <button @click="addMember()">Add</button>
    </div>
    <button v-if="new_members.length !=0" @click="addMembers()">저장</button>
    <div>
      <div v-for="user in new_members" :key="user" class="member-summary">
        <span>{{ user.kr_name }} / {{ user.eng_name }} / {{ user.type }} / {{ user.studentId }} </span>
      </div>
    </div>
  </div>
</template>

<script>
import axios from '../../../../axios';
import _ from 'lodash';

export default {
  data() {
    return {
      members: [], // Load members from API or state management
      updatedMembers: {},
      selectedMemberId: null,
      editingMemberId: null,
      users: [], // Load user data from API or state management,
      new_members: [],
      etag: null,
      previewImages: {},
      newPreviewImage: null
    };
  },
  methods: {
    imgUrl(url){
      return `${process.env.VUE_APP_API_URL}/member${url}`
    },
    checkExistUserId(user_id, target) {
      return !!target.find(m => m.user_id === user_id);
    },
    filterUsers(users) {
      return users.filter(user => !this.checkExistUserId(user.id, this.members));
    },
    toggleDropdown(id) {
      this.selectedMemberId = this.selectedMemberId === id ? null : id;
    },
    async updateAllData(){
      if(Object.keys(this.updatedMembers).length === 0){
        console.log("error");
        return;
      }
      try {
        const formData = new FormData();
        for (const key in this.updatedMembers) {
          formData.append('members', JSON.stringify(this.updatedMembers[key]));
          if (this.updatedMembers[key].image) {
            formData.append('images', this.updatedMembers[key].image);
          }
        }
        const response = await axios.put(`${process.env.VUE_APP_API_URL}/member/list`, formData, {
          headers: {
            'If-Match': this.etag,
            'Content-Type': 'multipart/form-data'
          }
        });
        this.editingMemberId = null;
        this.updatedMembers = {};
        console.log("sad", response.data.new)
        this.members = response.data.new;
        this.previewImages = {};
      } catch (err) {
        console.error(err);
      }
    },
    async addMembers(){
      if(this.new_members.length === 0){
        console.log("error");
        return;
      }
      try {
        const formData = new FormData();
        this.new_members.forEach(member => {
          formData.append('members', JSON.stringify(member));
          if (member.image) {
            formData.append('images', member.image);
          }
        });
        const response = await axios.post(`${process.env.VUE_APP_API_URL}/member/list`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        });
        this.new_members = [];
        this.members = response.data.new;
        this.newPreviewImage = null;
      } catch (err) {
        console.error(err);
      }
    },
    onFileChange(event, memberId) {
      const file = event.target.files[0];
      if (memberId) {
        this.updatedMembers[memberId].image = file;
        this.previewImages[memberId] = URL.createObjectURL(file);
      } else {
        this.newPreviewImage = URL.createObjectURL(file);
      }
    },
    addUserToMember(userId){
      if(this.checkExistUserId(userId, this.new_members)){
        return;
      }
      this.new_members.push({...this.getUserById(userId),
        user_id: userId
      });
    },
    editMember(id) {
      this.editingMemberId = id;
      if(!this.updatedMembers[id]){
        this.updatedMembers[id] = _.omit(this.getMemberById(id), ['user']);
      }
    },
    saveChanges() {
      // Save changes logic here
      this.editingMemberId = null;
    },
    cancelEdit(id) {
      this.editingMemberId = null;
      if(this.updatedMembers[id]){
        delete this.updatedMembers[id]
      }
    },
    isEditing(id) {
      return this.editingMemberId === id;
    },
    getMemberById(id){
      return this.members.find(m => m.id === id);
    },
    getUserById(id){
      if(!this.users){
        return {};
      }
      return this.users.find(m => m.id === id);
    },
    isUserChanged(id) {
      const member = this.getMemberById(id);
      if(member && member.user){
        const kr_name = member.kr_name == member.user.kr_name;
        const eng_name = member.eng_name == member.user.eng_name;
        const intro = member.intro == member.user.intro;
        const type = member.type == member.user.type;
        if( kr_name && eng_name && intro && type ) {
          return false;
        } else {
          return true;
        }
      } else{
        return false;
      }
    },
    syncWithUser(memberId) {
      const member = this.getMemberById(memberId);
      const user = this.getUserById(member.user_id) || member.user;
      const temp = this.updatedMembers[memberId] || {};
      this.updatedMembers[memberId] = {
        ...temp,
        ...user,
        id: memberId
      };
    },
    loadMembers() {
      axios.get(`${process.env.VUE_APP_API_URL}/member/list`).then(res=>{
        this.members = res.data.members;
        this.etag = res.headers.get('etag');
      }).catch(error=>{
        console.error(error);
      })
    },
    loadUsers() {
      axios.get(`${process.env.VUE_APP_API_URL}/member/users`).then(res=>{
        this.users = this.filterUsers(res.data.users);
      }).catch(error=>{
        console.error(error);
      })
    },
    clearFields() {
      this.$refs.kr_name.value = '';
      this.$refs.eng_name.value = '';
      this.$refs.type.value = '';
      this.$refs.intro.value = '';
      this.$refs.user_id.value = '';
      this.$refs.image.value = '';
      this.newPreviewImage = null;
    },
    addMember() {
      const kr_name = this.$refs.kr_name.value;
      const eng_name = this.$refs.eng_name.value;
      const type = this.$refs.type.value;
      const intro = this.$refs.intro.value;
      const user_id = this.$refs.user_id.value;
      const image = this.$refs.image.files[0];
      if(kr_name && eng_name && type && intro){
        console.log("hi~");
      } else {
        return;
      }
      const newMember = {
        user_id,
        kr_name,
        eng_name,
        type,
        intro,
        image
      };
      this.new_members.push(newMember); // 새로운 멤버를 배열에 추가
      this.clearFields(); // 입력 필드 초기화
    }
  },
  created() {
    this.loadMembers();
  },
};
</script>

<style scoped>
.member-item {
  border: 1px solid #ddd;
  margin-bottom: 10px;
  padding: 10px;
}

.member-summary {
  display: flex;
  justify-content: space-between;
  cursor: pointer;
}

.member-dropdown {
  margin-top: 10px;
}

.error {
  color: red;
}
</style>
