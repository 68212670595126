<template>
  <div id="app">
    <h1>넙스 관리</h1>
    <div>
      <h2>About</h2>
      <textarea ref="intro" v-model="about.intro" placeholder="넙스 소개"></textarea>
      미리보기:
      <div class="pre-view" v-html="convertToHtml(about.intro)"></div>
      <input type="file" ref="introimg">
      <button @click="insertImageTag('intro')">이미지 삽입</button>

      <textarea ref="symbol" v-model="about.symbol" placeholder="넙스 상징물"></textarea>
      미리보기:
      <div class="pre-view" v-html="convertToHtml(about.symbol)"></div>
      <input type="file" ref="symbolimg">
      <button @click="insertImageTag('symbol')">이미지 삽입</button>

      <textarea ref="greeting" v-model="about.greeting" placeholder="인사말"></textarea>
      미리보기:
      <div class="pre-view" v-html="convertToHtml(about.greeting)"></div>
      <input type="file" ref="greetingimg">
      <button @click="insertImageTag('greeting')">이미지 삽입</button>

      <textarea ref="purpose" v-model="about.purpose" placeholder="설립목적"></textarea>
      미리보기:
      <div class="pre-view" v-html="convertToHtml(about.purpose)"></div>
      <input type="file" ref="purposeimg">
      <button @click="insertImageTag('purpose')">이미지 삽입</button>

      <button @click="updateAbout">Update About</button>
    </div>
    <div>
      <h2>History</h2>
      <div v-for="(item, index) in history" :key="index">
        <p>{{ item.year }}: {{ item.event }}</p>
        <img :src="item.image" v-if="item.image" alt="이벤트 이미지 미리보기" />
      </div>
      <input v-model="newHistory.year" type="date" placeholder="년도">
      <textarea v-model="newHistory.event" placeholder="이벤트"></textarea>
      <input type="text" v-model="newHistory.image" placeholder="이벤트 이미지 URL">
      <img :src="newHistory.image" v-if="newHistory.image" alt="이벤트 이미지 미리보기" />

      <button @click="addHistory">Add History</button>
    </div>
  </div>
</template>


<script>
import axios from '../../../../axios';

export default {
  data() {
    return {
      about: {
        intro: '',
        symbol: '',
        greeting: '',
        purpose: ''
      },
      history: [],
      newHistory: {
        year: '',
        event: '',
        image: ''
      },
      currentImageIndex: 1,
      uploadedImages: {}
    }
  },
  created() {
    this.fetchAbout()
    this.fetchHistory()
  },
  methods: {
    async fetchAbout() {
      try {
        const response = await axios.get(`${process.env.VUE_APP_API_URL}/about/detail`)
        this.about = {
          intro: this.convertToText(response.data.intro),
          symbol: this.convertToText(response.data.symbol),
          greeting: this.convertToText(response.data.greeting),
          purpose: this.convertToText(response.data.purpose)
        }
      } catch (error) {
        console.error('Error fetching about data:', error)
      }
    },
    async updateAbout() {
      try {
        const updatedAbout = {
          intro: this.convertToHtml(this.about.intro),
          symbol: this.convertToHtml(this.about.symbol),
          greeting: this.convertToHtml(this.about.greeting),
          purpose: this.convertToHtml(this.about.purpose)
        }
        await axios.put(`${process.env.VUE_APP_API_URL}/about/detail`, updatedAbout)
        alert('About updated successfully')
      } catch (error) {
        console.error('Error updating about data:', error)
      }
    },
    async fetchHistory() {
      try {
        const response = await axios.get(`${process.env.VUE_APP_API_URL}/about/history`)
        this.history = response.data
      } catch (error) {
        console.error('Error fetching history data:', error)
      }
    },
    async addHistory() {
      try {
        await axios.post(`${process.env.VUE_APP_API_URL}/about/history`, this.newHistory)
        this.history.push(this.newHistory)
        this.newHistory = { year: '', event: '', image: '' }
        alert('History added successfully')
      } catch (error) {
        console.error('Error adding history data:', error)
      }
    },
    async insertImageTag(refName) {
      const textarea = this.$refs[refName]
      const img = this.$refs[refName+"img"].files[0];
      const cursorPosition = textarea.selectionStart
      const text = textarea.value
      const tag = `<이미지 ${this.currentImageIndex}>`
      const imgUrl = await this.uploadImage(img);
      this.uploadedImages[this.currentImageIndex] = imgUrl;
      this.about[refName] = text.slice(0, cursorPosition) + tag + text.slice(cursorPosition)
      this.currentImageIndex++;
    },
    convertToHtml(text) {
      return text.replace(/\n/g, '<br>').replace(/<이미지 (\d+)>/g, (_match, p1) => {
        return `<img src="${process.env.VUE_APP_API_URL}${this.uploadedImages[p1]}" class="post-img" alt="이미지">`
      })
    },
    convertToText(text) {
      return text.replace(/<br\s*\/?>/gi, '\n');
    },
    async uploadImage(file) {
      const formData = new FormData()
      formData.append('file', file)
      try {
        const response = await axios.post(`${process.env.VUE_APP_API_URL}/about/upload`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        })
        const imageUrl = response.data.url
        console.log(imageUrl)
        return imageUrl;
      } catch (error) {
        console.error('Error uploading image:', error)
      }
    }
  }
}

</script>


<style scoped>
textarea {
  width: 100%;
  height: 100px;
  margin-bottom: 10px;
}
input {
  display: block;
  margin-bottom: 10px;
}
img {
  max-width: 100%;
  height: auto;
  margin-bottom: 10px;
}
button {
  margin-bottom: 20px;
}
.pre-view {
  width: 80%;
  max-width: 1200px; 
}
</style>
