<template>
    <div class="notice-section">
        <div class="title-container">
            <div class="title">
                <span>공지 사항</span>
            </div>
            <div class="title-line"></div>
        </div>
        <div class="notice-container">
            <div class="notice" v-for="notice in notices" :key="notice.title"> 
                <div>
                    <div class="notice-title-container">
                        <div class="notice-title">
                            <span>{{notice.title}}</span>
                        </div>
                        <div class="notice-line"></div>
                    </div>
                    <div class="content" >
                        <p v-html="notice.content"></p>
                    </div>
                </div>
               
                <div class="more-btn"></div>
            </div>
        </div>
        <div class="back"></div>
    </div>  
</template>

<script>
import axios from 'axios';
export default {
    data() {
        return {
            notices: [
                {
                    title: "",
                    content: ``
                },
                {
                    title: "",
                    content: ``
                },
                {
                    title: "",
                    content: ``
                }
            ]
        }
    },
    async created() {
        try{
            const data = await axios.get(`${process.env.VUE_APP_API_URL}/board/post?amount=3`, {
                params: {
                    category: ["Notice"]
                }
            });
            const a = []
            data.data.posts.forEach(d => {
                const temp = { id: d.id, title: d.title, date:d.post_date, content: d.content};
                a.push(temp)
            });
            this.notices = a
        } catch(error){
            console.error(error);
        }
    }
}
</script>

<style scoped>
.notice-section{
    border: none;
    height: 540px;
    position: relative;
}
.notice-container {
    margin-top: 50px;
}
.notice {
    width: 30%;
    height: calc(320px);
    border: 2px solid rgba(253, 252, 184, 0.7);
    margin: 0 auto;
    background-color: rgba(112,112,112,0.302);
    color: rgba(235, 235, 235, 1);
}
.notice > div {
    margin: 25px;
}
.notice-title{
    font-style: normal;
    font-weight: bold;
    font-size: 26px;
    color: rgba(235, 235, 235, 1);
}
.content{
    text-overflow: ellipsis;
    font-size: 20px;
}
.notice-line {
    margin: 10px 0;
    background-color: rgba(235,235,235,1);
    width: 100%;
    height: 2px;
}
.back {
    width: 100%;
    background-image: url('@/assets/Mask_Group_7.png');
    height: 100%;
    position: absolute;
    filter: brightness(55%);
    left: 0px;
    background-position: center center;
    top: 0px;
    z-index: -1;
}

.notice-container {
    display: flex;
}

.title-container{
    margin: 0 auto;
    text-align: center;
}
.title-line{
    background-color: rgba(206, 206, 206, 1);
    height: 2px;
    width: 600px;
    margin: 0 auto;
}
.title {
    font-style: normal;
    font-weight: bold;
    font-size: 26px;
    color: rgba(253, 252, 184, 1);
    padding-top: 35px;
    padding-bottom: 10px;
}

</style>