import mutations from './mutaions'
import actions from './actions'
import getters from './getters'

const state = {
  token: null,
  tokenExpiration: null,
  userId: null,
  userInfo: {
    userImage: null,
  }
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
};