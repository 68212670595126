

export default {
    isValid(state) {
        console.log(state)
        return state.tokenValidate
    }, 
    userId(state) {
        return state.userId;
    },
    userImage(state) {
        return `${process.env.VUE_APP_API_URL}/member${state.userInfo.userImage}`;
    },
    token(state) {
        return state.token;
    },
    isAuthentication(state) {
        const userId = state.userId;
        const token = state.token;
        const expiresIn = state.tokenExpiration;
        if(!userId || !token || !expiresIn){
            return false;
        }
        const expirationDate = new Date(expiresIn);
        
        const currentDate = new Date();

        return currentDate < expirationDate;
    }
}