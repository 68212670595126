<!-- src/components/HistoryTimeline.vue -->
<template>
<div class="about-container">
    <p v-html="a">
    </p>
    <div class="timeline-container">
        <h1>연혁</h1>
        <div class="timeline">
        <div v-for="(item, index) in history" :key="index" class="timeline-item" :class="{'left': index % 2 === 0, 'right': index % 2 !== 0}">
            <div class="timeline-content">
            <h2>{{ item.year }}</h2>
            <ul>
                <li>{{ item.event }}</li>
            </ul>
            </div>
        </div>
        </div>
    </div>
</div>

</template>

<script>
import axios from 'axios'
export default {
    data() {
        return {
            a: null,
            history: []
        }
    },
    async created(){
        try {
            const response = await axios.get(`${process.env.VUE_APP_API_URL}/about/detail`)
            const response2 = await axios.get(`${process.env.VUE_APP_API_URL}/about/history`)
            this.a = response.data.purpose;
            this.history = response2.data;
            console.log("hi", this.histroy)
        } catch (error) {
            console.error('Error fetching history data:', error)
        }
    }
}
</script>

<style scoped>
.timeline-container {
  padding: 20px;
  text-align: center;
}

.timeline {
  position: relative;
  max-width: 1200px;
  margin: 0 auto;
  width: 100%; /* 추가 */
}

.timeline::after {
  content: '';
  position: absolute;
  width: 6px;
  background-color: #e9ecef;
  top: 0;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%); /* 중앙에 위치하도록 수정 */
  /* margin-left: -3px; 제거 */
}

.timeline-item {
  padding: 10px 40px;
  position: relative;
  background-color: inherit;
  width: 50%;
  box-sizing: border-box; /* 추가 */
}

.timeline-item.left {
  left: 0;
}

.timeline-item.right {
  left: 50%;
}

.timeline-item::after {
  content: ' ';
  position: absolute;
  width: 25px;
  height: 25px;
  background-color: white;
  border: 4px solid #007bff;
  top: 15px;
  border-radius: 50%;
  z-index: 1;
}

.timeline-item.left::after {
  right: -17px;
}

.timeline-item.right::after {
  left: -17px;
}

.timeline-content {
  padding: 20px 30px;
  background-color: white;
  position: relative;
  border-radius: 6px;
  box-shadow: 0 2px 10px rgba(0,0,0,0.1); /* 추가 */
}

.timeline-content h2 {
  margin-top: 0;
  margin-bottom: 10px;;
  color: #007bff;
}

.timeline-content ul {
  padding-left: 20px;
  list-style-type: none;
}

.timeline-content ul li {
  margin-bottom: 10px;
}

</style>
