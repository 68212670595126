<template>
<div class="about-container">
    <p v-html="a"></p>
</div>
</template>

<script>
import axios from 'axios'
export default {
    data() {
        return {
            a: null,
        }
    },
    async created(){
        try {
            const response = await axios.get(`${process.env.VUE_APP_API_URL}/about/detail`)
            this.a = response.data.greeting;
        } catch (error) {
            console.error('Error fetching history data:', error)
        }
    }
}
</script>

<style>

</style>