<template>
    <div class="research-container">
        <div v-if="localResearch.isAllowed !== undefined">
          <span v-if="!localResearch.isAllowed">관리자의 승인을 기다리는 중인 연구과제입니다.</span>
        </div>
        <div class="field">
            <label>Name:</label>
            <input v-model="localResearch.name" />
        </div>
        <div class="field">
            <label>Color:</label>
            <input type="color" v-model="localResearch.color" class="color-picker" />
            <input type="text" v-model="localResearch.color" />
        </div>
        <div class="field">
            <label>Introduction:</label>
            <textarea v-model="localResearch.introduction"></textarea>
        </div>
        <div class="field">
          <label>대표 이미지</label>
          <input type="file" @change="onFileChange" />
        </div>
        <div v-if="currentImage">
          현재 이미지:
          <div>
            <img :src="currentImage" alt="Preview" style="max-width: 200px; max-height: 200px;" />
          </div>
        </div>
        <div v-if="previewImage">
          새로운 이미지:
          <div>
            <img :src="previewImage" alt="Preview" style="max-width: 200px; max-height: 200px;" />
          </div>
        </div>
        <!-- <div class="field">
            <label>ID:</label>
            <input v-model="localResearch.id" disabled />
        </div> -->
        <button @click="submitForm(localResearch)">{{ buttonText }}</button>
    </div>
</template>

<script>
export default {
    props: {
      research: {
        type: Object,
        required: true
      },
      currentImage: {
        type: String,
        required: false
      },
      buttonText: {
        type: String,
        required: true
      },
      submitForm: {
        type: Function,
        required: true
      }
    },
    data() {
      return {
        localResearch: { ...this.research},
        previewImage: null,
      };
    },
    watch: { // research가 변경됐을때
      research: {
        handler(newValue) {
          this.localResearch = { ...newValue };
        },
        deep: true
      }
    },
    methods: {
      onFileChange(event) {
        this.localResearch.new_image = event.target.files[0];
        this.previewImage = URL.createObjectURL(this.localResearch.new_image);
      },
    },
    created() {
    }
};
</script>

<style scoped>
.research-container {
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 10px;
  background-color: #f9f9f9;
}

.field {
  margin-bottom: 20px;
}

.field label {
  display: block;
  font-weight: bold;
  margin-bottom: 5px;
}

.field input, .field textarea {
  width: 100%;
  padding: 8px;
  box-sizing: border-box;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.field .color-picker {
  width: 30px;
  height: 30px;
  padding: 0;
  border: none;
  background: none;
}

button {
  background-color: #0056b3;
  color: white;
  border: none;
  border-radius: 5px;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
}

button:hover {
  background-color: #004494;
}
</style>