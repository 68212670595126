<template>
    <div class="research-list-container">
        <div class="research-section">
            <h2>승인된 연구</h2>
            <ul class="research-list">
                <li v-for="research in approvedResearchList" :key="research.id">
                    <div class="research-header">
                        <div @click="toggleDropdown(research.id)" class="research-title">
                            {{ research.name }} <span class="dropdown-btn">자세히 보기</span>
                        </div>
                    </div>
                    <div v-if="isDropdownOpen(research.id)" class="research-details">
                        <p><strong>Color:</strong> <span :style="{ backgroundColor: research.color }">{{ research.color }}</span></p>
                        <p><strong>Introduction:</strong> {{ research.introduction }}</p>
                        <div v-if="research.image_url">
                            <img :src="research.image_url" alt="Preview" style="max-width: 200px; max-height: 200px;" />
                        </div>
                    </div>
                </li>
            </ul>
        </div>

        <div class="research-section">
            <h2>승인을 기다리는 연구</h2>
            <ul class="research-list">
                <li v-for="research in pendingResearchList" :key="research.id">
                    <div class="research-header">
                        <div @click="toggleDropdown(research.id)" class="research-title">
                            {{ research.name }} <span class="dropdown-btn">자세히 보기</span>
                        </div>
                        <div class="actions">
                            <button @click="approveResearch(research.id)">승인</button>
                            <button @click="rejectResearch(research.id)">거절</button>
                        </div>
                    </div>
                    <div v-if="isDropdownOpen(research.id)" class="research-details">
                        <p><strong>Color:</strong> <span :style="{ backgroundColor: research.color }">{{ research.color }}</span></p>
                        <p><strong>Introduction:</strong> {{ research.introduction }}</p>
                        <div v-if="research.image_url">
                            <img :src="research.image_url" alt="Preview" style="max-width: 200px; max-height: 200px;" />
                        </div>
                    </div>
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
import axios from '../../../../axios';

export default {
    data() {
        return {
            researchList: [],
            openDropdowns: []
        };
    },
    computed: {
        approvedResearchList() {
            return this.researchList.filter(research => research.isAllowed);
        },
        pendingResearchList() {
            return this.researchList.filter(research => !research.isAllowed);
        }
    },
    created() {
        this.fetchResearchList();
    },
    methods: {
        fetchResearchList() {
            axios.get(`${process.env.VUE_APP_API_URL}/research/list`)
                .then(response => {
                    this.researchList = response.data.map(research => {
                        if (research.image_url) {
                            research.image_url = `${process.env.VUE_APP_API_URL}/research${research.image_url}`;
                        }
                        return research;
                    });
                    console.log(response.data)
                })
                .catch(error => {
                    console.error('Error fetching research:', error);
                });
        },
        toggleDropdown(researchId) {
            const index = this.openDropdowns.indexOf(researchId);
            if (index > -1) {
                this.openDropdowns.splice(index, 1);
            } else {
                this.openDropdowns.push(researchId);
            }
        },
        isDropdownOpen(researchId) {
            return this.openDropdowns.includes(researchId);
        },
        approveResearch(id) {
            axios.put(`${process.env.VUE_APP_API_URL}/research/${id}/approve`)
                .then(() => {
                    alert('Research가 승인되었습니다.');
                    this.fetchResearchList();
                })
                .catch(error => {
                    console.error('Error approving research:', error);
                });
        },
        rejectResearch(id) {
            axios.put(`${process.env.VUE_APP_API_URL}/research/${id}/reject`)
                .then(() => {
                    alert('Research가 거절되었습니다.');
                    this.fetchResearchList();
                })
                .catch(error => {
                    console.error('Error rejecting research:', error);
                });
        }
    },
}
</script>

<style scoped>
.research-list-container {
    padding: 20px;
}

.research-section {
    margin-bottom: 40px;
}

.research-list {
    list-style-type: none;
    padding: 0;
}

.research-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.research-title {
    cursor: pointer;
    font-weight: bold;
    margin-bottom: 5px;
}

.dropdown-btn {
    font-size: 12px;
    color: #007bff;
    margin-left: 10px;
}

.actions button {
    margin-left: 10px;
}

.research-details {
    padding-left: 20px;
    margin-bottom: 15px;
    border-left: 2px solid #ccc;
}
</style>
