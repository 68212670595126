<template>
	<home-common :code="1" :data="news" />
</template>
<script>
    import HomeCommon from './HomeCommon.vue'; 
    import axios from 'axios';

	export default {
        name: "HomeResearch",
        components:{
            HomeCommon,
        },
		data() {
			return {
				news: [
                    { id: 1, categoryLink: '', category: {
                    name: "연구 분류",
                    id: 123,
                    color: "#c16565"
                }, content: '' },
                { id: 1, categoryLink: '', category: {
                    name: "연구 분류",
                    id: 123,
                    color: "#c16565"
                }, content: '' }
                ,{ id: 1, categoryLink: '', category: {
                    name: "연구 분류",
                    id: 123,
                    color: "#c16565"
                }, content: '' },{ id: 1, categoryLink: '', category: {
                    name: "연구 분류",
                    id: 123,
                    color: "#c16565"
                }, content: '' }
				]
			};
		},
        async created() {
            const a =[]
            for(let i = 0; i < 4; i++){
                a.push({ id: 1, categoryLink: '', category: {
                    name: "연구 분류",
                    id: 123,
                    color: "#c16565"
                }, content: '' });
            }
            this.news = a
            try{
                const data = await axios.get(`${process.env.VUE_APP_API_URL}/board/post?amount=4`, {
                    params: {
                        category: ["Notice", "News"]
                    }
                });
                console.log(data.data);
                const a = []
                data.data.posts.forEach(d => {
                    const temp = { id: d.id, categoryLink: d.category, category: {
                        name: "넙스 뉴스",
                        id: '001-001',
                        color: "#c16565"
                    }, content: d.title, date:d.post_date };
                    a.push(temp)
                });
                const b = a.length
                for(let i = 0; i < 4 -b; i++){
                    a.push({ id: 1, categoryLink: '', category: {
                        name: "연구 분류",
                        id: 123,
                        color: "#c16565"
                    }, content: '' });
                }
                this.news = a
            } catch(error){
                console.error(error);
            }
        },
	}
</script>

<style scoped>
</style>
